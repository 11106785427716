<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="dataForm.name" v-on="$listeners" width="90%">
    <div class="mod-__paper">
        <el-row>
          <el-col :span="9">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>图纸名称</span>
              </div>
              <div style="height: 32px">
                {{dataForm.name}}
              </div>
            </el-card>
          </el-col>
          <el-col :span="5">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>最新版本</span>
              </div>
              <div style="height: 32px">
                <el-link type="primary" @click="openPaperHandle(dataForm.id)">{{dataForm.version}}</el-link>
              </div>
            </el-card>
          </el-col>
          <el-col :span="5">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>分部分项</span>
              </div>
              <wbs-tree :prjId="dataForm.prjId" v-model="dataForm.wbsId" :wbs-name.sync="dataForm.wbsName" :wbs-pnames.sync="dataForm.wbsPNames" @change="wbsChange"></wbs-tree>
            </el-card>
          </el-col>
          <el-col :span="5">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>图纸目录</span>
              </div>
              <papercatalog-tree :prjId="dataForm.prjId" v-model="dataForm.catalogId" :catalog-name.sync="dataForm.catalogName" @change="catalogChange"></papercatalog-tree>
            </el-card>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>图纸描述</span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="saveDescriptionHandle">保存</el-button>
              </div>
              <el-input
                type="textarea"
                autosize
                placeholder="添加描述"
                maxlength="1000"
                show-word-limit
                v-model="dataForm.description">
              </el-input>
            </el-card>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>图纸标签</span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="saveNewTagHandle">添加</el-button>
              </div>
              <el-tag
                :key="tag"
                v-for="tag in dataForm.tagsList"
                closable
                :disable-transitions="false"
                @close="delTagHandle(tag)">
                {{tag}}
              </el-tag>
              <el-divider></el-divider>
              <el-input
                placeholder="填写新的标签"
                v-model="newTag"
                maxlength="100"
                show-word-limit
                size="small"></el-input>
            </el-card>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-card class="box-card">
              <div slot="header">
                <span>版本历史</span>
              </div>
              <div v-for="item in versionList" :key="item.id">
                <el-button type="text" @click="openPaperHandle(item.id)">
                  {{item.version}}{{item.id == dataForm.id ? "(当前版本)" : ""}}
                </el-button>
              </div>
            </el-card>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>专业</span>
              </div>
              <major-select placeholder="专业选择" v-model="dataForm.majorId" clearable @change="changeMajorHandle"></major-select>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>专业负责人</span>
              </div>
              <el-input v-model="dataForm.majorInCharge" placeholder="专业负责人" maxlength="100" show-word-limit @change="changeFormHandle('majorInCharge')"></el-input>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>设计阶段</span>
              </div>
              <el-select v-model="dataForm.stageId" @change="changeStageHandle">
                <el-option v-for="opt in stageArray" :label="opt.title" :key="opt.id" :value="opt.id"></el-option>
              </el-select>
            </el-card>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="6">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>设计人</span>
              </div>
              <el-input v-model="dataForm.designer" placeholder="设计人" maxlength="100" show-word-limit @change="changeFormHandle('designer')"></el-input>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>设计负责人</span>
              </div>
              <el-input v-model="dataForm.designInCharge" placeholder="设计负责人" maxlength="100" show-word-limit @change="changeFormHandle('designInCharge')"></el-input>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>复核人</span>
              </div>
              <el-input v-model="dataForm.reviewer" placeholder="复核人" maxlength="100" show-word-limit @change="changeFormHandle('reviewer')"></el-input>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>审核人</span>
              </div>
              <el-input v-model="dataForm.verifier" placeholder="审核人" maxlength="100" show-word-limit @change="changeFormHandle('verifier')"></el-input>
            </el-card>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>其他附件</span>
              </div>
              <my-upload :refId="dataForm.id" :typeId="uploadTypeIds.PAPER_ATTACHMENT"></my-upload>
            </el-card>
          </el-col>
        </el-row>
      </div>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import PapercatalogTree from './papercatalog-tree'
import WbsTree from './wbs-tree'
export default {
  data () {
    return {
      visible: false,
      stageArray: [
        {
          id: 1,
          title: '初步设计'
        },
        {
          id: 2,
          title: '施工图'
        }
      ],
      dataForm: {
        id: '',
        name: '',
        prjId: '',
        catalogId: '',
        catalogName: '',
        description: '',
        tagsList: [],
        identification: '',
        wbsId: '',
        wbsName: '',
        wbsPNames: '',
        majorId: '',
        stageId: '',
        designer: '',
        reviewer: '',
        verifier: '',
        designInCharge: '',
        majorInCharge: ''
      },
      newTag: '',
      // 历史版本
      versionList: [],
      newComment: '', // 图纸评论
      comments: [],
      // 对应附件类型分类枚举
      uploadTypeIds: {
        PAPER_SRC: '图纸源文件',
        PAPER_PDF: '图纸PDF文件',
        PAPER_PNG: '图纸图片',
        PAPER_THUMBNAIL: '图纸缩略图',
        PAPER_ATTACHMENT: '图纸附件',
      }
    }
  },
  components: {PapercatalogTree, WbsTree},
  created () {
    // this.dataForm.id = this.$route.params.id || ''
    // this.init()
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        this.getInfo()
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/paper/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        // 加载历史版本的图纸列表
        this.getVersionList()
        // 加载评论信息
        this.getCommentList()
      }).catch(() => {
        // do nothing
      })
    },
    // 保存图纸描述
    saveDescriptionHandle () {
      let data = {
        id: this.dataForm.id || '',
        description: this.dataForm.description || ''
      }
      this.$http.put(`/mps/paper`, data)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          } else {
            this.$message({
              duration: '1000',
              message: '描述信息保存成功！',
              type: 'success'
            })
          }
        }).catch(() => {
          // do nothing
        })
    },
    // 加载历史版本
    getVersionList () {
      this.$http.get(
        `/mps/paper/versionList/${this.dataForm.identification}?prjId=${this.dataForm.prjId}`
      ).then(({ data: res }) => {
        this.versionList = res.data || []
      }).catch(() => {
        // do nothing
      })
    },
    // 保存新的标签
    saveNewTagHandle () {
      if (this.newTag) {
        if (!this._containsTag(this.dataForm.tagsList, this.newTag)) {
          this.dataForm.tagsList.push(this.newTag)
          this._saveTags()
        }
      }
    },
    delTagHandle (tag) {
      this.dataForm.tagsList.splice(this.dataForm.tagsList.indexOf(tag), 1)
      this._saveTags()
    },
    // 点击图纸的版本历史（目前的做法是直接打开图纸对应的PDF文件）
    openPaperHandle (refId) {
      // 根据图纸上传时的原始Url，重新获取授权的Url（避免文件路径在不登录时就能随意访问的安全问题）
      this.$http.get(`/oss/file/list/${refId}?typeId=${this.uploadTypeIds.PAPER_PDF}`)
        .then(({ data: res }) => {
          if (res.data && res.data.length > 0) {
            let oss = res.data[0] // 理论上一个paper只能对应一个图纸文件
            window.open(oss.url)
          } else {
            this.$message({
              message: '对不起，未查询到相关文件！',
              type: 'error',
              duration: 5000
            })
          }
        })
    },
    _containsTag (arr, val) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] === val) {
          return true
        }
      }
      return false
    },
    _saveTags (tagsList) {
      let data = {
        id: this.dataForm.id || '',
        tagsList: this.dataForm.tagsList
      }
      this.$http.post(`/mps/paper/tags`, data)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          } else {
            this.$message({
              duration: '1000',
              message: '标签保存成功！',
              type: 'success'
            })
            this.newTag = ''
            this.dataForm.tagsList = res.data || []
          }
        }).catch(() => {
          // do nothing
        })
    },
    cloneObj (obj) {
      var newObj = {}
      if (obj instanceof Array) {
        newObj = []
      }
      for (var key in obj) {
        var val = obj[key]
        newObj[key] = typeof val === 'object' ? this.cloneObj(val) : val
      }
      return newObj
    },

    // 图纸目录变化事件
    catalogChange(newCatalogId){
      let data = {
        id: this.dataForm.id || '',
        catalogId: newCatalogId+'' || ''
      }
      this.$http.put(`/mps/paper`, data)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          } else {
            this.$message({
              duration: 1000,
              message: '图纸目录保存成功！',
              type: 'success'
            })
          }
        }).catch(() => {
        // do nothing
      })
    },

    // 图纸工程分解（WBS）变化事件
    wbsChange(newWbsId){
      let data = {
        id: this.dataForm.id || '',
        wbsId: newWbsId+'' || ''
      }
      this.$http.put(`/mps/paper`, data)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          } else {
            this.$message({
              duration: 1000,
              message: '图纸工程分解保存成功！',
              type: 'success'
            })
          }
        }).catch(() => {
        // do nothing
      })
    },

    // 修改专业
    changeMajorHandle () {
      //this.dataForm.majorId = o ? o.id : ''
      let data = {
        id: this.dataForm.id,
        key: 'majorId',
        majorId: this.dataForm.majorId,
      }
      this.$http.put('/mps/paper/design', data).then(({ data: res }) => {
        // do nothing
      }).catch(() => {
        // do nothing
      })
    },

    // 修改阶段
    changeStageHandle () {
      //this.dataForm.stageId = o ? o.id : ''
      let data = {
        id: this.dataForm.id,
        key: 'stageId',
        stageId: this.dataForm.stageId,
      }
      this.$http.put('/mps/paper/design', data).then(({ data: res }) => {
        // do nothing
      }).catch(() => {
        // do nothing
      })
    },

    // 修改设计信息等字段
    changeFormHandle: debounce(function (key) {
      let data = {
        id: this.dataForm.id,
        key: key,
        designer: this.dataForm.designer,
        reviewer: this.dataForm.reviewer,
        verifier: this.dataForm.verifier,
        designInCharge: this.dataForm.designInCharge,
        majorInCharge: this.dataForm.majorInCharge
      }
      this.$http.put('/mps/paper/design', data).then(({ data: res }) => {
        // do nothing
      }).catch(() => {
        // do nothing
      })
    }, 1000, { 'leading': true, 'trailing': false }),

    
  }
}
</script>
<style>
  .el-tag + .el-tag {
    margin-left: 10px;
    margin-top: 10px;
  }
</style>
