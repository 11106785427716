<template>
  <el-card shadow="never" class="aui-card--fill" ref="element">
    <div>
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="设备名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList"></toolbar-button>
            <toolbar-button role="print" @click="openPrint">打印</toolbar-button>
            <toolbar-button role="export" @click="openExport">导出</toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <!--      <el-row :gutter="10">
          <el-col :span="24">
            <h1 class="my-report-title">设备台账</h1>
          </el-col>
        </el-row>-->
      <vxe-table
          border
          resizable
          show-overflow
          ref="tableRef"
          :data="dataList"
          :loading="dataListLoading"
          :height="tableHeight"
          :row-config="{isCurrent: true, isHover: true}"
          :print-config="{}"
          :export-config="{}">
        <vxe-column type="seq" title="序号" width="60" align="center"></vxe-column>
        <vxe-column field="name" title="设备名称"></vxe-column>
        <vxe-column field="spec" title="规格型号" width="160" align="center"></vxe-column>
        <vxe-column field="manufacturer" title="生产厂家" width="240"></vxe-column>
        <vxe-column field="producedDate" title="生产日期" width="100" align="center"></vxe-column>
        <vxe-column field="expirationDate" title="到期检验日期" width="100" align="center"></vxe-column>
        <vxe-column field="inDate" title="有效期" width="100" align="center"></vxe-column>
        <vxe-column field="arrivalDate" title="到场日期" width="100" align="center"></vxe-column>
        <vxe-column field="isExit" title="是否退场" width="90" align="center">
          <template #default="{ row }">
            <vxe-button v-if="row.isExit==1" type="text" status="success">已退场</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </el-card>
</template>
<!--设备台账-->
<script>
import mixinViewModule from '@/mixins/view-module'
import XEUtils from "xe-utils";

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/equipmentInAndOut/report/list',
        getDataListIsPage: false,
      },
      dataForm: {
        contractId: '',
        name: ''
      },
      tableHeight: 400,

      // 打印顶部内容模板
      printTopHtml: `<h1 class="my-report-title">设备台账</h1>`,
      printConfig: {
        // 自定义打印的样式示例
        style: `.my-report-title {
                  text-align: center;
                }
                `,
        // 输出数据的方式列表 current:当前页数据、selected:选中数据、all:全量（所有页数据）
        modes: ['current'],
        // 打印之前
        beforePrintMethod: ({content}) => {
          // 拦截打印之前，返回自定义的 html 内容
          return this.printTopHtml + content
        }
      },
      // vxeTable 导出配置
      exportConfig: {
        modes: ['current']
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 220
    })
  },
  methods: {
    // 标段切换
    contractChange(data) {
      this.dataList = []
      this.dataForm.contractId = data.contractId
      this.getDataList()
    },
    // vxeTable打印报表
    openPrint() {
      this.$refs.tableRef.openPrint(this.printConfig)
    },
    // vxeTable高级导出
    openExport() {
      this.$refs.tableRef.openExport(this.exportConfig)
    },
  }
}
</script>
<style lang="scss" scoped>
.my-report-title {
  text-align: center;
}
</style>