<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container>
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader ref="contractCascader" include-sub @change="contractChange" style="width: 96%;"></contract-cascader>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree lazy v-if="leftTreeVisible"
            class="left-tree" 
            node-key="id" v-loading="leftTreeLoading"
            :data="leftTreeData"
            :props="leftTreeProps"
            :load="loadLeftTreeNode"
            :expand-on-click-node="false"
            @node-click="leftTreeNodeClick"
            :default-expanded-keys="expandedKeys"
            :highlight-current="true">
            <span slot-scope="{ node, data }">
              {{ `${data.code ? data.code : ''} ${node.label}` }}
            </span>
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__meas">
          <el-form :inline="true">
            <el-form-item>
              <el-button-group style="margin-top: -3px;">
                <toolbar-button role="lock" v-if="dataForm.contractId && locked < 1"  @click="lock()" :disabled="!$hasPermission('mps:sbs:lock')"></toolbar-button>
                <toolbar-button role="unlock" v-if="dataForm.contractId && locked >= 1" @click="unlock()" :disabled="!$hasPermission('mps:sbs:lock')"></toolbar-button>
                <toolbar-button role="import" v-if="dataForm.contractId && locked < 1" @click="importHandle" :disabled="!$hasPermission('mps:sbs:import')"></toolbar-button>
              </el-button-group>
              <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
                <el-tooltip content="汇总计算项目节各分项合同额" placement="top" effect="light">
                  <el-button icon="el-icon-s-operation" v-if="$hasPermission('mps:sbs:calculate')" @click="calculate()"></el-button>
                </el-tooltip>
              </el-button-group>
            </el-form-item>
          </el-form>
          <vxe-table
                ref="xTable"
                v-if="rightTopTableShow"
                :loading="rightTopDataLoading"
                :height="rightTopTableHeight"
                border
                auto-resize
                show-overflow
                keep-source
                :row-config="{isCurrent: true, isHover: true, useKey: true, keyField: 'id'}"
                :column-config="{resizable: true}"
                :tooltip-config="{theme: 'light'}"
                :tree-config="treeConfig"
                :edit-config="{enabled: locked < 1, trigger: 'click', mode: 'cell', showStatus: true, beforeEditMethod: beforeEditMethod}"
                :data="rightTopTableData"
                size="mini"
                @current-change="changeCurrentSbsHandle"
                @edit-closed="editClosedEvent"
                :footer-method="footerMethod"
                :show-footer="showFooter"
                :cell-class-name="cellClassName"
                footer-cell-class-name="mytable-footer-summary">
              <vxe-column field="name" min-width="300" title="名称" tree-node></vxe-column>
              <vxe-column field="code" min-width="200" title="编号"></vxe-column>
              <vxe-column field="unitName" width="100" title="单位" align="center"></vxe-column>
              <vxe-column field="num1" width="130" title="设计数量1" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}}"></vxe-column>
              <vxe-column field="num2" width="130" title="设计数量2" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}}"></vxe-column>
              <vxe-column field="money" width="130" title="合同金额（元）" align="right" :edit-render="{ name: 'MyInputNumber', props: {digits: amountAccuracy} }"></vxe-column>
            </vxe-table>
            <sbs-edit-boq v-if="sbsEditBoqVisible" ref="sbsEditBoq" :locked="locked" :tableHeight="rightBottomTableHeight" @calculateSbsMoney="calculateSbsMoneyHandle"></sbs-edit-boq>
            <!-- excel文件导入弹窗 -->
            <sbs-edit-import v-if="sbsEditImportVisible" ref="sbsEditImport" @refreshDataList="refreshPageData"></sbs-edit-import>
         </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
// 【标段分项清单编制】
import qs from 'qs'
import XEUtils from 'xe-utils'
import SbsEditBoq from './sbs-edit-boq'
import SbsEditImport from './sbs-edit-import'
export default {
  data () {
    return {
      dataForm: {
        prjId: '',
        contractId: '',
        subcontractorId: ''
      },

      // 左侧项目节树
      leftTreeVisible: false,
      leftTreeLoading: false,
      leftTreeData: [],
      leftTreeProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'isLeaf'
      },
      expandedKeys: [],

      // 右上项目节树表格
      treeConfig: {
        transform: false,
        rowField: 'id',
        parentField: 'pid',
        // line: true,
        lazy: true,
        hasChild: 'hasChildren',
        loadMethod: this.loadRightTopChildren,
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus'
      },
      rightTopTableData: [],
      rightTopTableShow: true,
      rightTopTableHeight: 400,
      rightTopDataLoading: false,
      currentSbsRow: null,
      showFooter: false,
      // 清单是否锁定，0：是，1：否
      locked: 0,

      // 右下零号清单明细
      sbsEditBoqVisible: false,
      rightBottomTableHeight: 350,
      // 导入弹窗
      sbsEditImportVisible: false,
      amountAccuracy: 0, // 金额小数位精度
    }
  },
  components: {
    SbsEditBoq,
    SbsEditImport
  },
  mounted () {
    this.$nextTick(() => {
      this.rightTopTableHeight = window.innerHeight - this.rightBottomTableHeight - 170
      if (this.rightTopTableHeight < 250) {// 如果高度太小，则重新调整上下版面的高度
        this.rightBottomTableHeight = 250
        this.rightTopTableHeight = window.innerHeight - this.rightBottomTableHeight - 170
      }
    })
  },
  methods: {
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId
      this.locked = data.contract && data.contract.lockSubBoq != null ? data.contract.lockSubBoq : 0
      this.amountAccuracy = data.contract && data.contract.amountAccuracy ? data.contract.amountAccuracy : 0
      if (data.subcontractor) {
        this.locked = data.subcontractor && data.subcontractor.lockSubBoq != null ? data.subcontractor.lockSubBoq : 0
      }
      this.leftTreeVisible = true
      this.refreshPageData()
    },
    
    lock() {
      this.$confirm(`确定要锁定分项清单吗？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let url = ''
        if (this.dataForm.subcontractorId) {
          url = `/mps/sbs/lockSubBoqSubcontractor/${this.dataForm.subcontractorId}/1`
        } else {
          url = `/mps/sbs/lockSubBoq/${this.dataForm.contractId}/1`
        }
        this.$http.put(url).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.locked = 1
          // 因为清单锁定的状态来源于下拉框中的数据，所以此处需要反向更新下拉框中的对应字段
          // 避免下拉框选了别的选项后又选回本选项（在页面没刷新的情况下），locked字段会被下拉框回传的数据更新，导致页面的锁定状态不正常
          if (this.hasSubcontractor) {
            this.$refs.contractCascader.updateSubcontractorData(this.dataForm.subcontractorId, "lockSubBoq", 1)
          } else {
            this.$refs.contractCascader.updateContractData(this.dataForm.contractId, "lockSubBoq", 1)
          }
          this.$message({
            message: '已锁定',
            type: 'warning',
            duration: 1500,
          })
        }).finally(() => {
          loading.close()
        })
      }).catch(e => e)
    },
    unlock() {
      if (this.locked == 2) {
        this.$message({
          message: '已进行清单编修，不允许解锁！',
          type: 'warning',
          duration: 1500,
        })
      }
      this.$confirm(`确定要解锁分项清单吗？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let url = ''
        if (this.dataForm.subcontractorId) {
          url = `/mps/sbs/unlockSubBoqSubcontractor/${this.dataForm.subcontractorId}/0`
        } else {
          url = `/mps/sbs/unlockSubBoq/${this.dataForm.contractId}/0`
        }
        this.$http.put(url).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          // 因为清单锁定的状态来源于下拉框中的数据，所以此处需要反向更新下拉框中的对应字段
          // 避免下拉框选了别的选项后又选回本选项（在页面没刷新的情况下），locked字段会被下拉框回传的数据更新，导致页面的锁定状态不正常
          if (this.hasSubcontractor) {
            this.$refs.contractCascader.updateSubcontractorData(this.dataForm.subcontractorId, "lockSubBoq", 0)
          } else {
            this.$refs.contractCascader.updateContractData(this.dataForm.contractId, "lockSubBoq", 0)
          }
          this.locked = 0
          this.$message({
            message: '已解锁',
            type: 'warning',
            duration: 1500,
          })
        }).finally(() => {
          loading.close()
        })
      }).catch(e => e)
    },
    importHandle () {
      this.sbsEditImportVisible = true
      this.$nextTick(() => {
        this.$refs.sbsEditImport.init(this.dataForm.contractId, this.dataForm.subcontractorId)
      })
    },

    // 重新计算分项金额
    calculate() {
      this.$confirm(`确定要重新计算项目节各层级合同额吗？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.get(`/mps/sbs/calculate`, {
          params: {
            contractId: this.dataForm.contractId,
            subcontractorId: this.dataForm.subcontractorId
          }
        }).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: '已全部计算完毕！',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.refreshPageData()
            }
          })
        }).finally(() => {
          loading.close()
        })
      }).catch(e => e)
    },

    refreshPageData() {
      // 加载左侧标段分项结构树
      this.getLeftTreeRootData();
      // 加载右上页面标段分项根节点
      this.getRightTopRootData(0)
      // 清空并隐藏右下页面
      this.clearSubBoqTable()
    },

    // 加载左侧标段分项结构树
    getLeftTreeRootData() {
      this.leftTreeLoading = true
      this.leftTreeData = []
      this.$http.get('/mps/sbs/children',
        {
          params: {
            pid: 0,
            ...this.dataForm
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data && res.data.length > 0) {
          this.leftTreeData = [
            {
              id: 0,
              pid: -1,
              name: '全部',
              isLeaf: false,
              children: res.data
            }
          ]
          // 实现默认展开第一级节点
          this.expandedKeys = [0]
        } else {
          this.leftTreeData = []
        }
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.leftTreeLoading = false
      })
    },

    // 异步加载左侧树的下级节点
    loadLeftTreeNode(node, resolve) {
      if (node.data && node.level >= 1) {
        let pid = node.data.id
        this.leftTreeLoading = true
        this.$http.get('/mps/sbs/children',
          {
            params: {
              pid: pid,
              ...this.dataForm
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data);
        }).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          this.leftTreeLoading = false
        })
      }
    },

    // 点击左侧标段分项树节点，过滤查询右上页面的标段分项数据
    leftTreeNodeClick(data, node, ele) {
      // 过滤加载右上页面标段分项的节点
      this.getRightTopRootData(data.id)
      // 清空并隐藏右下页面
      this.clearSubBoqTable()
      // 修编细目的上级节点也可能挂接了零号清单，所以也需要显示
      if ((data.isSbsLeaf == 1 && data.isLeaf == 0) || data.isLeaf == 1) {
        this.currentSbsRow = data
        this.showSubBoqTable(data)
      }
    },

    // 加载右上页面标段分项根节点
    getRightTopRootData(pid) {
      this.rightTopTableData = []
      this.rightTopTableShow = false
      this.$nextTick(() => {
        this.rightTopTableShow = true
      })
      if (this.dataForm.prjId) {
        this.rightTopDataLoading = true
        // 注意：点击左侧节点加载右侧数据的时候，为了方便用户使用，不光是加载下级节点，也会将点击的节点包括在内
        this.$http.get('/mps/sbs/meAndChildren',
          {
            params: {
              pid: pid,
              ...this.dataForm
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.rightTopTableData = res.data
          if (this.rightTopTableData && this.rightTopTableData.length == 1) {
            // 如果只查到一条分项，则默认选中
            this.$nextTick(() => {// 注意：必须使用nextTick，以便在xTable的DOM更新后，才能确保成功getRowById调用成功
              //let currentRow = this.$refs.xTable.getRowById(res.data[0].id)
              //this.$refs.xTable.setCurrentRow(currentRow)
              //this.changeCurrentSbsHandle({newValue: res.data[0], row: currentRow})
            })
          }
          if (this.rightTopTableData && this.rightTopTableData.length > 1) {
            this.showFooter = true
          } else {
            this.showFooter = false
          }
          this.rightTopDataLoading = false
        }).catch(() => {
          return this.$message.error('出错了')
        })
      }
    },
    // 异步加载右上标段分项子节点
    loadRightTopChildren({row}) {
      return new Promise(resolve => {
        this.$http.get('/mps/sbs/children',
          {
            params: {
              pid: row.id,
              ...this.dataForm
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          if (res.data && res.data.length > 1) {
            this.showFooter = true
          } else {
            this.showFooter = false
          }
          resolve(res.data)
        }).catch()
      })
    },

    // 单元格退出编辑事件
    editClosedEvent ({row, column}) {
      const $table = this.$refs.xTable
      const field = column.property
      // 判断单元格值是否被修改
      if ($table.isUpdateByRow(row, field)) {
        if (field === 'num1' || field === 'num2') {
          this.$http.put(`/mps/sbs/submitData/${field}`, row).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 1500,
            })
            // 局部更新单元格为已保存状态
            // this.refreshMyRow($table, row, field)
          }).catch(() => {
            return this.$message.error('出错了')
          })
        } else if (field === 'money') {
          this.$http.put(`/mps/sbs/submitData/${field}`, row).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                // 级联计算上级节点的金额
                this.sumEachTreeNode($table, this.currentSbsRow, field)
              }
            })
          }).catch(() => {
            return this.$message.error('出错了')
          })
        }

        // 必须在修改数据后执行合计行的更新，否则将导致合计行的数据不准确
        $table.updateFooter()
      }
    },

    /**
     * 恢复数据行的状态（不标记小三角）
     * @param table vxe table组件对象
     * @param row 行对象(最新修改的数据)
     * @param field 字段名
     */
     refreshMyRow ($table, row, field) {
      if ($table && row && field) {
        const {keepSource, tableSourceData, tableData} = $table
        if (keepSource) { // vxe table开启了 keep-source
          const newValue = XEUtils.get(row, field)
          let resultData = XEUtils.findTree(tableSourceData,item => item.id === row.id, {children: 'children'})
          if (resultData) {
            XEUtils.set(row, field, newValue)
            XEUtils.set(resultData.item, field, newValue)
          }
          $table.tableData = tableData.slice(0)
        }
      }
    },

    // 右上页面点击不同的节点，刷新右下页面对应的零号清单
    changeCurrentSbsHandle({newValue, row}) {
      this.currentSbsRow = row
      this.showSubBoqTable(row)
    },

    // 加载右下版面的零号清单
    showSubBoqTable({id: subItemId, code: subItemCode, name: subItemName, level: subItemLevel, isLeaf, isSbsLeaf}) {
      let msg = subItemCode ? (subItemCode + ' ' + subItemName) : subItemName
      if (msg && msg.length > 0) {
        this.$message({
          message: `正在查询【${msg}】的分项清单。`,
          type: 'success',
          duration: 3000,
        })
      }
      this.sbsEditBoqVisible = true
      this.$nextTick(() => {
        this.$refs.sbsEditBoq.dataForm.prjId = this.dataForm.prjId
        this.$refs.sbsEditBoq.dataForm.contractId = this.dataForm.contractId
        this.$refs.sbsEditBoq.dataForm.subcontractorId = this.dataForm.subcontractorId
        this.$refs.sbsEditBoq.dataForm.subItemId = subItemId
        this.$refs.sbsEditBoq.dataForm.subItemLevel = subItemLevel
        this.$refs.sbsEditBoq.dataForm.isLeaf = isLeaf
        this.$refs.sbsEditBoq.dataForm.isSbsLeaf = isSbsLeaf
        this.$refs.sbsEditBoq.init()
      })
    },

    // 清空右下版面的零号清单
    clearSubBoqTable() {
      if (this.sbsEditBoqVisible) {
        this.$refs.sbsEditBoq.clear()
        this.sbsEditBoqVisible = false
      }
    },
    // 修改分项清单的合同数量，需要重新计算标段分项的合同额
    calculateSbsMoneyHandle(sbsId) {
      // 后台递归汇总计算分项各层级的合同金额
      this.$http.put(`/mps/sbs/sbsMoney/${sbsId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        // 异步刷新页面中对应标段分项的合同金额
        XEUtils.set(this.currentSbsRow, 'money', res.data)
        // 异步刷新页面中项目节各层级的合同金额
        this.sumEachTreeNode(this.$refs.xTable, this.currentSbsRow, 'money')
      }).catch(() => {
        return this.$message.error('刷新项目节合同额时出现错误！')
      })
    },
    
    // 递归计算下级节点的金额
    handleSummary (children, fieldName) {
      if (fieldName === 'money') {
        return {
          //money: Math.floor(XEUtils.sum(children, 'money'))
          money: XEUtils.sum(children, 'money')
        }
      }
    },

    // 遍历标段分项树，重新每一级节点的计算金额
    sumEachTreeNode(tbl, currentRow, field) {
      XEUtils.eachTree(tbl.getTableData().fullData, (row,index,items,path,parent,nodes) => {
        let children = row.children
        if (children && children.length) {
          // 合计子节点
          Object.assign(row, this.handleSummary(children, field))
          // this.refreshMyRow(tbl, row, field)
        } else {
          if (index === items.length - 1) {
            // 全量汇总
            for (let len = nodes.length - 2; len >= 0; len--) {
              Object.assign(nodes[len], this.handleSummary(nodes[len].children, field))
              // this.refreshMyRow(tbl, nodes[len], field)
            }
          }
        }
      },{
        children: 'children',
        accordion: false, // 一层只允许展开一个节点
        expandAll: false // 默认是否全部展开
      })
      // this.refreshMyRow(this.$refs.xTable, currentRow, field)
    },
    
    // 合计
    footerMethod({ columns, data }) {
      const footerData = [
        columns.map((column, _columnIndex) => {
          if (_columnIndex === 0) {
            return '合计'
          }
          if (['money'].includes(column.field)) {
            return this.getFooterTotal(data, 'money')
          }
          return null
        }),
      ]
      return footerData
    },
    // 计算底部合计行中的相关金额
    getFooterTotal(data, columnName) {
      // 没有对data进行递归，仅计算了根节点（data中有children属性）
      if (columnName == 'money') {
        let total = 0
        for (let i = 0; i < data.length; i++) {
          total = XEUtils.add(total, data[i].money)
        }
        if (XEUtils.isNumber(this.amountAccuracy)) {
          return XEUtils.commafy(total, { digits: this.amountAccuracy })
        } else {
          return total
        }
      }
    },

    // 单元格是否允许编辑
    cellCanEdit(row, column){
      // 金额只允许叶子节点编辑，数量允许所有节点编辑
      if (!this.$hasPermission('mps:sbs:edit') || this.locked >= 1) {
        return false
      } else if (column.property === 'num1' || column.property === 'num2') {
          return true
      } else if ((column.property === 'money') && row.isLeaf) {
        return true
      }
      return false
    },

    // 单元格是否允许编辑
    beforeEditMethod ({row, rowIndex, column, columnIndex}) {
      return this.cellCanEdit(row, column)
    },

    cellClassName({row, column}) {
      if (this.cellCanEdit(row, column)) {
        return 'cell-underline'
      } else {
        return ''
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 160px);
  }
  ::v-deep .mytable-footer-summary {
    background-color: #D4E6F1;
    font-weight: bold;
  }
</style>